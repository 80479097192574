* {
  font-family: "Roboto", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
}


h1,
span,
svg,
p {
  color: white;
}

.disabled-button {
  opacity: 0.5;
  pointer-events: none;
}


.margin-l-auto {
  margin-left: auto;
}

.flex {
  display: flex !important;
}

.nowrap {
  white-space: nowrap;
}

.red {
  background-color: #ff0000b6 !important;
}

.defaultBlue {
  color: #0082c3 !important;
}

.darkRed {
  background-color: #f00 !important;
}

.green {
  background-color: #00d903 !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.underline {
  text-decoration: underline;
}

.black {
  color: #000;
}

.bold {
  font-weight: 700;
}

.ant-picker-cell-inner {
  color: #000 !important;
}

.ant-picker-header {
  background-color: #323232;
}

.ant-picker-header-view {
  color: #fff;
}

.welcomeText {
  font-size: 26px;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 16px;
}

.hp-welcome-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 16px;
}

.appLogo {
  width: 82px;
  height: 82px;
}

.logoContainer {
  margin-top: 1rem;
}

.techLogo {
  border-radius: 4px;
  height: auto;
  max-width: 300px;
  width: 100%;
}

.containerTitle {
  margin: 20px 0;
  font-size: 20px;
  display: block;
  font-weight: 600;
  color: black;
}

.loginContainer {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  position: relative;
}

.welcomeContainer {
  height: 80vh;
}

.buttonArea {
  display: block;
  text-align: center;
  border: none;
  flex-flow: column;
  background-color: #f1f1f1;
  border-radius: 6px;
  padding: 40px 20px;
  box-shadow: 0 0 20px 0 #5a5a57;
  width: 60%;
}

.loginButton {
  width: 200px;
  height: 50px;
}

.otherStore {
  color: white;
  background-color: white;
  box-shadow: 0px 0px 10px 0px #707070;
}

.fedidLogin {
  display: inline-block;
  font-size: 18px;
  background-color: #006cc5;
  font-weight: 700;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 40px 90px;
  cursor: pointer;
}

.selectOtherStoreButton {
  display: flex;
  margin: 30px auto;
  padding: 10px 70px;
  background-color: #da7121;
}

.popup-container {
  margin: 26px 20px;
}

.popup-staff {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 430px;
  min-height: 60px;
  border-radius: 6px;
  margin: 0 auto;
  background-color: #f42222;
}

.popup-text {
  width: 90%;
  margin: 8px auto;
  text-align: center;
  color: #fff;
  font-size: 14px;
}

.entranceButtons {
  display: flex;
  justify-content: space-evenly;
}

.defaultStore {
  display: inline-block;
  margin-top: 20px;
  font-size: 18px;
  background-color: #da7121;
  font-weight: 700;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 16px 28px;
  cursor: pointer;
}

.otherStoreButton {
  display: inline-block;
  margin-top: 20px;
  font-size: 18px;
  background-color: #0074af;
  opacity: 0.5;
  font-weight: 700;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 16px 28px;
  cursor: not-allowed;
}

.exitButton {
  display: inline-block;
  margin-top: 5px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 20px 40px;
  cursor: pointer;
}

.joinDrill {
  display: flex;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 8px 18px;
  margin-top: 6px;
  cursor: pointer;
}

.returnToStoreSelection {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  font-size: 20px;
  background-color: #f8f8f8;
  font-weight: 700;
  color: rgb(73, 73, 73);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 20px 60px;
}

.returnToUserlist {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  font-size: 22px;
  background-color: #f94f4f;
  font-weight: 700;
  color: #fff;
  box-shadow: 0px 0px 30px 0px #b52929;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 18px 70px;
}

.selectOtherStore {
  display: block;
  width: 50%;
  margin: 24px auto;
}

.adminSettings {
  border: none;
  position: fixed;
  cursor: pointer;
  top: 0;
  right: 0;
  padding: 20px 46px;
  border-radius: 0px 0px 0px 15px;
  background-color: #0074af;
  z-index: 95;
}

.admin-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #2196f3;
  padding: 40px;
  border-radius: 4px;
}

.MuiMenu-list {
  color: black;
}

.locationFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.currentLocationDiv {
  margin-bottom: 20px;
}

.textField {
  display: flex;
  align-items: center;
  color: black;
  font-weight: 500;
  border: 1px solid rgba(146, 146, 146, 0.542);
  box-shadow: 0 0 5px 0 #6e6e6e;
  padding: 5px 10px;
}

.currentLocationText {
  color: #000000b8;
  font-size: 18px;
  line-height: 15px;
  margin-right: 10px;
}

.svgIcons {
  width: 0.7em !important;
  height: 0.8em !important;
  transform: scale(3);
}

.notFound {
  font-size: 80px;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 230px;
}

.usersContainer {
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

.userListContainer {
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  padding: 0 20px;
  justify-content: center;
}

.guestListContainer {
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  padding: 0 20px;
  justify-content: center;
}

.flexForm {
  display: flex;
  flex-direction: column;
}

.addGuestContainer, .addGuestModalContainer {
  display: flex;
  text-align: center;
  border: none;
  margin: 50px auto;
  flex-flow: column;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 40px 30px;
  box-shadow: 0 0 20px 0 #5a5a57;
}

.addGuestContainer {
  margin: 50px auto;
  width: 50%;
}

.addGuestModalContainer {
  margin: 0 auto;
  width: 50%;
}

.userListRow {
  background-color: #0082c3;
  position: sticky;
  top: -1px;
}

.userListRow>th {
  color: white;
  font-weight: bold;
  white-space: pre;
}

.storeEmpty {
  display: flex;
  justify-content: center;
  background-color: white;
  color: black;
  font-size: 20px;
  max-width: 540px;
  text-align: center;
  margin: 0 auto;
  border-radius: 6px;
}

.showUnitsReturnBut {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 16px 60px;
  background-color: #0082c3;
  color: white;
  box-shadow: 0px 0px 10px 0px #b8b8b8;
}

.drillButtonArea {
  width: 100%;
}

.drillButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 12px;
  background-color: #f8f8f8;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  box-shadow: 0px 0px 10px 0px #b8b8b8;
}

.startDrill {
  background-color: #0082c3;
  margin-bottom: 6px;
}

.completeDrill {
  background-color: #22bc24;
  margin-bottom: 6px;
}

.deleteDrill {
  background-color: #f94f4f;
}

.CookieConsent {
  max-width: 700px;
  padding: 20px 30px;
  background-color: #2b373b;
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate3d(-50%, -50%, 9999990px);
  width: 100%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.missingFieldsAlert {
  background-color: #db413d !important;
}

.successfulAlert {
  background-color: #20aa62 !important;
}

#rcc-confirm-button {
  display: flex;
  margin: 0 auto;
  font-size: 14px;
  color: black;
  padding: 14px;
  font-weight: bold;
  cursor: pointer;
}

.overlayclass {
  position: fixed;
  background-color: #000;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.returnToAdmin {
  padding: 20px;
  padding: 10px 30px;
  margin-bottom: 20px;
  background-color: #54b6f7;
  color: white;
  box-shadow: 0px 0px 10px 0px rgb(143, 142, 142);
}

.initStoresContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 22px;
  font-weight: bold;
}

.initStoresContainer>p {
  margin: 15px 0;
}

.initArea {
  display: block;
  text-align: center;
  border: none;
  flex-flow: column;
  background-color: #2e64a1;
  padding: 40px 30px;
  box-shadow: 0 0 20px 0 #5a5a57;
  width: 50%;
  margin: 0 auto;
  margin-top: 250px;
}

.not-allowed {
  cursor: not-allowed;
  opacity: 0.5;
}

.exitUserButton {
  display: flex !important;
  justify-content: center;
  width: 2rem !important;
  height: 2rem !important;
  margin: 0 auto;
  fill: black !important;
  cursor: pointer;
}

.redBackground {
  background-color: red !important;
}

.greenBackground {
  background-color: green;
}

.maintenance-container {
  position: absolute;
  background-color: #0074bc;
  opacity: 0.9;
  height: 100%;
}

.sub-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error-image h1 {
  display: flex;
  max-width: 720px;
  width: 90%;
  margin: auto;
  text-align: center;
}

.error-msg-container {
  margin: 18px auto 30px auto;
  max-width: 800px;
  width: 100%;
  text-align: center;
}

.error-msg-container h1 {
  font-size: 56px;
  white-space: nowrap;
  margin: auto auto 48px;
}

.error-msg-container p {
  font-size: 20px;
  line-height: 28px;
}

.maintenance-icon {
  display: flex !important;
  justify-content: center !important;
}

/* Easter Area */
.easterPhoto {
  display: flex;
  border-radius: 6px;
  margin: 10rem auto 1rem auto;
  width: 80%;
}

/* COUNTER AREA */
.counter-area {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: linear-gradient(to right, #00d903 52%, #f94f4f 50%);
  border-radius: 6px;
  margin: 0 auto 12px auto;
  max-width: 120px;
  padding: 6px 0;
}

.counter-item {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 5px;
  justify-items: center;
}

.MedicalServicesIcon,
.LocalFireDepartmentIcon {
  width: 50px;
  height: 50px;
}

.counter-item>span {
  font-size: 18px;
}

.drill-main-container {
  display: flex;
  text-align: center;
  border: none;
  margin: 30px auto 20px auto;
  flex-flow: column;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 18px;
  box-shadow: 0 0 20px 0 #5a5a57;
  width: 50%;
}

.list-seperator {
  margin: 10px 0;
  border-bottom: 1px solid black;
  width: 100%;
}

.active-drill-list {
  padding: 0;
}

.active-drill-list>li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
}

.active-drill-list>li>span,
.active-drill-list>li>span>strong {
  color: #000;
}

.li-drill-item {
  margin-bottom: 2rem;
  padding: 16px;
  border-radius: 4px;
  background-color: #dedede;
}

.joinDrillButtonSvg {
  background: none;
  display: block;
  shape-rendering: auto;
  position: relative;
  left: 0;
  animation: drillMoveLogo 0.5s linear infinite alternate;
}

/* LOADING SCREEN FOR FEDID */
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.loadingSvg, .suspenseLoadingSvg {
  background: none;
  display: block;
  shape-rendering: auto;
  position: relative;
  left: 0;
  animation: moveLogo 0.2s linear infinite alternate;
}

.loadingSvg {
  width: 130px;
  height: 130px;
}

.suspenseLoadingSvg {
  width: 64px;
  height: 64px;
}

/* CSS ANIMATIONS  */
@keyframes moveLogo {
  from {
    left: -30px;
  }

  to {
    left: 30px;
  }
}

@keyframes drillMoveLogo {
  from {
    left: -20px;
  }

  to {
    left: -10px;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.rotate {
  animation: rotation 2.8s infinite linear;
}

/* MOBILE DEVICES */
@media screen and (max-width: 520px) {
  body {
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }

  .w-70 {
    width: 70% !important;
  }

  .w-80 {
    width: 80% !important;
  }

  .w-90 {
    width: 90% !important;
  }

  .ant-picker-datetime-panel {
    flex-direction: column !important;
  }

  .addGuestModalContainer {
    width: 85%;
  }

  .hp-welcome-text {
    margin: 30px auto 16px auto;
    font-size: 22px;
  }

  .drill-main-container {
    width: 90%;
  }

  .textField {
    margin-top: 1rem;
  }

  .admin-buttons {
    margin: 0 50px;
    padding: 30px;
  }

  .returnToStoreSelection {
    padding: 20px 50px;
  }

  .storeEmpty {
    max-width: 350px;
  }

  .addGuestContainer {
    width: 80%;
  }

  .buttonArea {
    width: 80%;
  }

  .textField,
  .entranceButtons,
  .locationFlex {
    display: block;
  }

  .welcomeText {
    margin-top: 30px;
  }

  .defaultStore {
    font-size: 16px;
  }

  .adminSettings {
    z-index: 10;
    position: fixed;
    cursor: pointer;
    bottom: 0;
    top: auto;
    left: 0;
    display: block;
    border-radius: 0;
  }

  .CookieConsent {
    width: 70%;
  }

  .warnBox {
    font-size: 15px;
  }

  .fedidLogin {
    padding: 30px 90px;
  }

  .initArea {
    margin-top: 120px;
    width: 70%;
  }

  .initStoresContainer {
    font-size: 20px;
  }

  .hiddenRow {
    display: none !important;
  }

  .usersTable {
    min-width: 180px !important;
  }

  .exitUserButton {
    margin: 0 auto !important;
  }

  th {
    width: 50% !important;
  }

  .timeText {
    padding: 3px !important;
  }

  .error-msg-container h1 {
    font-size: 36px;
  }
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: portrait) {
  .adminSettings {
    z-index: 10;
    padding: 22px 46px;
    border-radius: 0;
  }

  .svgIcons {
    width: 0.8em;
    height: 0.8em;
  }

  .welcomeText {
    margin: 25px 0px 25px 0px;
    font-size: 25px;
  }

  .showUnitsReturnBut {
    padding: 10px;
  }

  .returnToUserlist {
    width: 80%;
    padding: 25px;
  }

  .addGuestContainer {
    margin: 15px auto;
  }

  .buttonArea {
    padding: 15px 15px;
  }

  .admin-buttons {
    margin: 30px 0px;
    width: 95%;
  }

  .defaultStore {
    width: 100%;
    font-size: 17px;
  }

  .otherStoreButton {
    width: 100%;
    font-size: 17px;
  }

  .warnText {
    font-size: 20px;
  }

  #rcc-confirm-button {
    padding: 15px;
  }

  .storeEmpty {
    font-size: 18px;
  }

  .userListRow>th:nth-child(4) {
    font-weight: bold;
    padding: 5px;
  }

  .fedidLogin {
    font-size: 14px;
    padding: 20px 0px;
    width: 100%;
  }

  .returnToStoreSelection {
    margin-top: 25px;
    padding: 15px;
    width: 100%;
  }

  .warnBox>h1 {
    font-size: 20px;
  }

  .initStoresContainer>p {
    font-size: 20px;
  }

  .selectOtherStore {
    width: 88%;
  }

  .otherStore {
    margin: 0px 0px 20px 0px !important;
  }

  .initArea {
    margin-top: 90px;
    width: 70%;
  }

  .textField {
    margin-left: 0px;
  }
}