:global(.sidebarNav) {
    position: sticky;
    top: 0;
    z-index: 90;
}

.menuSliderContainer {
    width: 250px;
    height: 100%;
}

.langSelector {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.6rem;
    row-gap: 8px;
}

.pageNavigator>span {
    color: #000;
}